import { useRouter } from 'next/router'
import React, { FunctionComponent, useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'

import StandardButton from 'design-system/Buttons/StandardButton'
import Body3 from 'design-system/Typography/Body/Body3'
import Body4 from 'design-system/Typography/Body/Body4'
import CallOut2 from 'design-system/Typography/CallOut/CallOut2'
import Caption from 'design-system/Typography/Caption/Caption'
import Heading2 from 'design-system/Typography/Heading/Heading2'
import Subheading1 from 'design-system/Typography/Subheading/Subheading1'
import Title1 from 'design-system/Typography/Title/Title1'

import RoundedCheckmarkCircleFilled from 'components/SVG/RoundedCheckmarkCircleFilled'
import CircleVortex from 'components/SVG/illustration/CircleVortex'
import Spacer from 'components/Spacer'
import ViewportMarginContainer from 'components/ViewportMarginContainer'

import { trackPreselectedPackage } from 'services/analytics'

import PricingPlansData from 'data/PricingPlansData'

const GreyBackground = styled.div`
  background-color: ${props => props.theme.colors.neutral25};
  width: 100%;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (min-width: ${props => props.theme.metrics.tablet}px) {
    height: 100%;
  }
`

interface ISelectedProp {
  selected?: boolean
}
const PricingPlansContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: ${props => props.theme.colors.white};
  border-radius: 25px;
  @media (min-width: ${props => props.theme.metrics.desktop}px) {
    flex-direction: row;
  }
`

const PricingCard = styled.div<ISelectedProp>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${props => props.selected && `background-color: ${props.theme.colors.blue900};`}
  padding: 20px;
  border-radius: 25px;
  width: 100%;
  @media (min-width: ${props => props.theme.metrics.tablet}px) {
    padding: 36px;
  }
  @media (min-width: ${props => props.theme.metrics.desktop}px) {
    width: 33.3%;
    padding: 20px 42px 60px 42px;
  }
`

const PricingCardContent = styled.div`
  margin-bottom: 20px;
`

const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (min-width: ${props => props.theme.metrics.desktop}px) {
    flex-direction: column-reverse;
    height: 70px;
  }
`

const Label = styled.div`
  font-size: 10px;
  text-align: center;
  font-weight: ${props => props.theme.fonts.weights.heavy};
  letter-spacing: 1px;
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.gold600};
  text-transform: uppercase;
  padding: 8px 16px 6px 16px;
  border-radius: 12px;
  width: 125px;
  align-self: flex-end;
  @media (min-width: ${props => props.theme.metrics.desktop}px) {
    margin-right: -20px;
  }
`

const List = styled.div`
  display: flex;
  margin: 10px 0;
  svg {
    flex-shrink: 0;
  }
`

const CircleVortexContainer = styled.div`
  position: relative;
  svg {
    position: absolute;
    right: -40px;
    bottom: -80px;
    z-index: 1;
    @media (min-width: ${props => props.theme.metrics.tablet}px) {
      width: 250px;
      bottom: -100px;
    }
    @media (min-width: ${props => props.theme.metrics.desktop}px) {
      width: 120px;
      bottom: -150px;
    }
  }
`

const PricingPlan: FunctionComponent = () => {
  const theme = useContext(ThemeContext)
  const router = useRouter()

  const trackPreselectedPackageAndRedirect = (preselectedPackageName: string) => () => {
    trackPreselectedPackage(preselectedPackageName)
    router.push('/listing/create')
  }

  return (
    <GreyBackground>
      <Spacer
        size={theme.spacings[32]}
        tabletSize={theme.spacings[48]}
        desktopSize={theme.spacings[56]}
      />
      <ViewportMarginContainer center>
        <TextContainer>
          <Spacer
            size={32}
            hideTablet
            hideDesktop
          />
          <Heading2 textAlign="center">Everything you need. Nothing you don&apos;t.</Heading2>
          <Spacer size={16} />
          <Subheading1
            textAlign="center"
            color="neutral400"
          >
            Sellers have different needs so we have different pricing plans. Three levels of
            support, plus add-ons to get you the help you need. Zero complications.
          </Subheading1>
        </TextContainer>
        <Spacer
          size={theme.spacings[24]}
          tabletSize={theme.spacings[32]}
          desktopSize={theme.spacings[48]}
        />
        <PricingPlansContainer>
          {PricingPlansData.map((pricingPlan, index) => {
            const selected = index === 1
            return (
              <PricingCard
                key={pricingPlan.type}
                selected={selected}
              >
                <PricingCardContent>
                  <LabelContainer>
                    <Title1 color={selected ? 'white' : 'neutral900'}>{pricingPlan.type}</Title1>
                    {index === 1 && <Label>Most Popular</Label>}
                  </LabelContainer>
                  <Spacer size={12} />
                  <CallOut2 color={selected ? 'white' : 'neutral900'}>{pricingPlan.price}</CallOut2>
                  <Spacer size={4} />
                  <Body3 color={selected ? 'white' : 'neutral500'}>
                    {pricingPlan.closingPrice}
                  </Body3>
                  <Spacer
                    size={16}
                    desktopSize={40}
                  />
                  <Body4 color={selected ? 'white' : 'neutral500'}>{pricingPlan.helperText}</Body4>
                  {pricingPlan.details.map(detail => (
                    <List key={detail}>
                      <RoundedCheckmarkCircleFilled
                        width="20px"
                        height="20px"
                        fill={selected ? theme.colors.transparentWhiteAlpha10 : theme.colors.teal50}
                        checkmarkColor={selected ? theme.colors.white : theme.colors.blue500}
                      />
                      <Spacer size={12} />
                      <Body4 color={selected ? 'white' : 'neutral500'}>{detail}</Body4>
                    </List>
                  ))}
                </PricingCardContent>
                {selected && (
                  <CircleVortexContainer>
                    <CircleVortex />
                  </CircleVortexContainer>
                )}
                <StandardButton
                  type="primary"
                  label="Choose plan"
                  size="medium"
                  zIndex="level1"
                  backgroundColor="teal500"
                  fullWidth
                  center
                  onClick={trackPreselectedPackageAndRedirect(pricingPlan.type)}
                />
              </PricingCard>
            )
          })}
        </PricingPlansContainer>
        <Spacer
          size={theme.spacings[16]}
          tabletSize={theme.spacings[24]}
          desktopSize={theme.spacings[32]}
        />
        <Caption
          textAlign="center"
          color="neutral300"
        >
          Pricing varies by state, click below to learn more.
        </Caption>
        <Spacer
          size={theme.spacings[36]}
          tabletSize={theme.spacings[40]}
          desktopSize={theme.spacings[56]}
        />
        <StandardButton
          type="primary"
          label="Learn More"
          href="/pricing"
          size="medium"
          center
        />
      </ViewportMarginContainer>
      <Spacer
        size={theme.spacings[32]}
        tabletSize={theme.spacings[48]}
        desktopSize={theme.spacings[56]}
      />
    </GreyBackground>
  )
}

export default PricingPlan
