import { FunctionComponent, useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'

import ListingCard from 'design-system/Card/ListingCard'
import { Grid, GridItem } from 'design-system/Layouts/Grid'
import Heading2 from 'design-system/Typography/Heading/Heading2'

import Spacer from 'components/Spacer'
import ViewportMarginContainer from 'components/ViewportMarginContainer'

import { useRecentlySoldListingsStatic } from 'services/swr/listings/useRecentlySoldListings'

const WidthContainer = styled.div`
  width: 100%;
`

const RecentlySoldListings: FunctionComponent = () => {
  // theme
  const theme = useContext(ThemeContext)

  const { recentlySoldListings } = useRecentlySoldListingsStatic()

  return (
    <ViewportMarginContainer center>
      <Spacer
        size={theme.spacings[32]}
        tabletSize={theme.spacings[48]}
        desktopSize={theme.spacings[56]}
      />
      <Heading2 textAlign="center">Recently Sold Homes</Heading2>
      <Spacer
        size={theme.spacings[36]}
        tabletSize={theme.spacings[40]}
        desktopSize={theme.spacings[56]}
      />
      {!!recentlySoldListings && !!recentlySoldListings.length && (
        <WidthContainer>
          <Grid
            gridColumns="1fr"
            gridColumnsDesktop="repeat(3, 1fr)"
            columnGap="24px"
            rowGap="36px"
            rowGapTablet="40px"
          >
            {recentlySoldListings
              && recentlySoldListings.map(listing => (
                <GridItem key={listing._id}>
                  <ListingCard listing={listing} />
                </GridItem>
              ))}
          </Grid>
        </WidthContainer>
      )}
      <Spacer
        size={theme.spacings[32]}
        tabletSize={theme.spacings[48]}
        desktopSize={theme.spacings[72]}
      />
    </ViewportMarginContainer>
  )
}

export default RecentlySoldListings
