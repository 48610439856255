import { FunctionComponent, useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'

import AddressSearchInput from 'design-system/Inputs/AddressSearchInput'
import Heading2 from 'design-system/Typography/Heading/Heading2'
import Subheading1 from 'design-system/Typography/Subheading/Subheading1'

import BackgroundImage from 'components/BackgroundImage'
import Spacer from 'components/Spacer'
import ViewportMarginContainer from 'components/ViewportMarginContainer'

const SignUpContainer = styled.div`
  position: relative;
  width: 100%;
  height: 480px;

  @media (min-width: ${props => props.theme.metrics.tablet}px) {
    height: 325px;
  }

  @media (min-width: ${props => props.theme.metrics.desktop}px) {
    height: 425px;
  }
`

const ContentContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: ${props => props.theme.zIndices.level2};
`

const MaxWidthContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  max-width: 720px;
`

const SignUp: FunctionComponent = () => {
  const theme = useContext(ThemeContext)
  return (
    <SignUpContainer>
      <BackgroundImage
        backgroundSize="cover"
        imgSrc={`${process.env.CDN_BASE_URL}/q_auto,w_1280/amazon/assets/hp-b/hands-on-keyboard-typing-overlay.jpg`}
        imgSrcTablet={`${process.env.CDN_BASE_URL}/q_auto,w_1440/amazon/assets/hp-b/hands-on-keyboard-typing-overlay.jpg`}
        imgSrcDesktop={`${process.env.CDN_BASE_URL}/q_auto,w_1920/amazon/assets/hp-b/hands-on-keyboard-typing-overlay.jpg`}
      >
        <ViewportMarginContainer center>
          <ContentContainer>
            <Heading2
              textAlign="center"
              color="white"
            >
              Sell your home and save tons of money along the way.
            </Heading2>
            <Spacer size={theme.spacings[16]} />
            <Subheading1
              textAlign="center"
              color="white"
            >
              Enter your details to create your free listing account today. Savings await...
            </Subheading1>
            <Spacer
              size={theme.spacings[56]}
              responsiveSize={theme.spacings[36]}
            />
            <MaxWidthContainer>
              <AddressSearchInput type="colorTypeA" />
            </MaxWidthContainer>
          </ContentContainer>
        </ViewportMarginContainer>
      </BackgroundImage>
    </SignUpContainer>
  )
}

export default SignUp
