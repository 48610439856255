import useSWR from 'swr'

import * as T from 'types'

import { fetch } from '../../fetchers'

interface IPaginationOptions {
  limit: number
  skip: number
}

export const useRecentlySoldListingsStatic = () => {
  const url = `/listings/recently-sold-contract/static`
  const response = useSWR<T.IListing[]>(url, fetch)
  return { ...response, recentlySoldListings: response.data }
}

export const useRecentlySoldListings = () => {
  const response = useSWR<T.IListing[]>('/listings/recently-sold-contract', fetch)
  return { ...response, recentlySoldListings: response.data }
}

export const useRecentlySoldListingsOld = ({ limit = 9, skip = 0 }: IPaginationOptions) => {
  const key = `/listings/sales?limit=${limit}&skip=${skip}`
  const response = useSWR<{ data: T.IListing[]; count: number }>(key, fetch)
  return { ...response, recentlySoldListings: response?.data?.data, count: response?.data?.count }
}
