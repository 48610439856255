import { SVGIcon } from '../Icons'
import { ISVGProps } from '../types'

const SmileyFace = (props: ISVGProps) => (
  <SVGIcon {...props}>
    <svg
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.8141 0.166504C9.31415 0.166504 0.00164795 9.49984 0.00164795 20.9998C0.00164795 32.4998 9.31415 41.8332 20.8141 41.8332C32.335 41.8332 41.6683 32.4998 41.6683 20.9998C41.6683 9.49984 32.335 0.166504 20.8141 0.166504ZM20.835 37.6665C11.6266 37.6665 4.16831 30.2082 4.16831 20.9998C4.16831 11.7915 11.6266 4.33317 20.835 4.33317C30.0433 4.33317 37.5016 11.7915 37.5016 20.9998C37.5016 30.2082 30.0433 37.6665 20.835 37.6665ZM28.1266 18.9165C29.8558 18.9165 31.2516 17.5207 31.2516 15.7915C31.2516 14.0623 29.8558 12.6665 28.1266 12.6665C26.3975 12.6665 25.0016 14.0623 25.0016 15.7915C25.0016 17.5207 26.3975 18.9165 28.1266 18.9165ZM13.5433 18.9165C15.2725 18.9165 16.6683 17.5207 16.6683 15.7915C16.6683 14.0623 15.2725 12.6665 13.5433 12.6665C11.8141 12.6665 10.4183 14.0623 10.4183 15.7915C10.4183 17.5207 11.8141 18.9165 13.5433 18.9165ZM20.835 32.4582C25.6891 32.4582 29.8141 29.4165 31.4808 25.1665H10.1891C11.8558 29.4165 15.9808 32.4582 20.835 32.4582Z"
        fill="#FFA51E"
      />
    </svg>
  </SVGIcon>
)

export default SmileyFace
