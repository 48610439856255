import { FunctionComponent, useContext } from 'react'
import { ThemeContext } from 'styled-components'

import TestimonialsABTest from 'modules/Testimonials/TestimonialsABTest'

import Heading2 from 'design-system/Typography/Heading/Heading2'
import Subheading1 from 'design-system/Typography/Subheading/Subheading1'

import Spacer from 'components/Spacer'
import ViewportMarginContainer from 'components/ViewportMarginContainer'

import { TestimonialDataHomepageB } from 'data/MarketingData/TestimonialData'

const Testimonials: FunctionComponent = () => {
  const theme = useContext(ThemeContext)

  return (
    <ViewportMarginContainer center>
      <Spacer
        size={theme.spacings[32]}
        tabletSize={theme.spacings[48]}
        desktopSize={theme.spacings[72]}
      />
      <Heading2 textAlign="center">Take it from our customers.</Heading2>
      <Spacer size={theme.spacings[16]} />
      <Subheading1
        textAlign="center"
        color="neutral400"
      >
        Testimonials
      </Subheading1>
      <Spacer
        size={theme.spacings[36]}
        tabletSize={theme.spacings[40]}
        desktopSize={theme.spacings[56]}
      />
      <TestimonialsABTest
        type="homepage"
        data={TestimonialDataHomepageB}
      />
      <Spacer
        size={theme.spacings[32]}
        tabletSize={theme.spacings[48]}
        desktopSize={theme.spacings[72]}
      />
    </ViewportMarginContainer>
  )
}

export default Testimonials
