import { SVGIcon } from '../Icons'
import { ISVGProps } from '../types'

const MoneyBag = (props: ISVGProps) => (
  <SVGIcon {...props}>
    <svg
      width="45"
      height="44"
      viewBox="0 0 45 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.331 6.27041e-05L15.3468 0H29.4802L29.496 6.27041e-05C30.2063 0.00568748 30.9108 0.183879 31.5397 0.526955C32.1693 0.870405 32.7098 1.3725 33.09 1.99833C33.4713 2.62582 33.6731 3.34885 33.6607 4.09209C33.6483 4.83523 33.4226 5.55063 33.022 6.16475C32.9776 6.23295 32.929 6.29837 32.8765 6.36064L30.5888 9.07663C34.6603 11.2209 37.9174 13.735 40.2513 16.7824C43.045 20.43 44.4135 24.7086 44.4135 29.7112C44.4135 34.7583 41.8633 38.4522 37.7765 40.7742C33.8083 43.0289 28.4129 44 22.4135 44C16.4138 44 11.0201 43.0287 7.05336 40.7835C2.96987 38.4723 0.413513 34.7952 0.413513 29.7727C0.413513 24.7698 1.78216 20.4918 4.57501 16.8409C6.91098 13.7873 10.1721 11.2649 14.2495 9.108L11.9464 6.3557C11.8955 6.29491 11.8483 6.23115 11.805 6.16475C11.4044 5.55064 11.1787 4.83524 11.1663 4.09209C11.1539 3.34884 11.3558 2.62581 11.737 1.99833C12.1173 1.3725 12.6578 0.870407 13.2873 0.526955C13.9162 0.183879 14.6207 0.00568748 15.331 6.27041e-05ZM15.3581 4C15.3022 4.0011 15.2536 4.0141 15.217 4.0313L18.9473 8.48916C19.3465 8.96618 19.501 9.60176 19.3654 10.2088C19.2297 10.8158 18.8194 11.3252 18.2552 11.5869C13.4289 13.8258 9.9881 16.3482 7.75202 19.2712C5.54487 22.1565 4.41351 25.5589 4.41351 29.7727C4.41351 33.1679 6.02382 35.6045 9.02367 37.3025C12.1403 39.0665 16.7466 40 22.4135 40C28.0808 40 32.6854 39.0664 35.8005 37.2964C38.7971 35.5938 40.4135 33.1434 40.4135 29.7112C40.4135 25.4972 39.282 22.0953 37.0757 19.2145C34.8406 16.2962 31.4011 13.7817 26.5765 11.5583C26.0097 11.2972 25.5974 10.7864 25.4615 10.1774C25.3256 9.56833 25.4818 8.93076 25.8838 8.45349L29.609 4.03084C29.5726 4.01388 29.5244 4.00109 29.4689 4H15.3581Z"
        fill="#FFA51E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.4135 29.3389C23.5181 29.3389 24.4135 30.2343 24.4135 31.3389V34.4418C24.4135 35.5464 23.5181 36.4418 22.4135 36.4418C21.309 36.4418 20.4135 35.5464 20.4135 34.4418V31.3389C20.4135 30.2343 21.309 29.3389 22.4135 29.3389Z"
        fill="#FFA51E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.4135 13.9478C23.5181 13.9478 24.4135 14.8432 24.4135 15.9478V18.8356C24.4135 19.9402 23.5181 20.8356 22.4135 20.8356C21.309 20.8356 20.4135 19.9402 20.4135 18.8356V15.9478C20.4135 14.8432 21.309 13.9478 22.4135 13.9478Z"
        fill="#FFA51E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.271 20.9845C21.7784 20.9496 21.312 21.0974 20.9729 21.3601C20.7144 21.5603 20.5538 21.8058 20.4789 22.0531C20.5565 22.3038 20.7202 22.5518 20.9813 22.7541C21.3231 23.0188 21.7913 23.169 22.287 23.1379C23.7193 23.0479 25.155 23.4702 26.2928 24.3514C27.4351 25.2361 28.2003 26.5286 28.3661 27.9789C28.3834 28.1299 28.3834 28.2824 28.3661 28.4334C28.2003 29.8837 27.4351 31.1762 26.2928 32.0609C25.182 32.9212 23.7872 33.3441 22.3889 33.2799C21.512 33.3001 20.6368 33.1421 19.8239 32.8124C18.9798 32.4701 18.2175 31.9484 17.6029 31.2759C16.8576 30.4606 16.9144 29.1956 17.7297 28.4503C18.545 27.7051 19.8101 27.7619 20.5553 28.5772C20.7541 28.7947 21.0156 28.9793 21.3271 29.1056C21.6389 29.2321 21.9846 29.2933 22.3356 29.2798C22.403 29.2772 22.4705 29.278 22.5378 29.2823C23.0336 29.3134 23.5017 29.1631 23.8435 28.8985C24.1016 28.6986 24.2645 28.4539 24.3432 28.2061C24.2645 27.9584 24.1016 27.7137 23.8435 27.5138C23.5017 27.2491 23.0336 27.0989 22.5378 27.13C21.1055 27.22 19.6698 26.7977 18.532 25.9165C17.3897 25.0318 16.6246 23.7393 16.4587 22.289C16.4421 22.1443 16.4414 21.9981 16.4566 21.8533C16.6098 20.3922 17.3745 19.0876 18.5236 18.1977C19.6338 17.3378 21.0289 16.9178 22.4256 16.9868C23.2742 16.9553 24.1238 17.0889 24.9205 17.3826C25.7569 17.691 26.5216 18.171 27.1542 18.7985C27.9384 19.5764 27.9435 20.8427 27.1657 21.6269C26.3878 22.4111 25.1214 22.4162 24.3373 21.6383C24.1247 21.4275 23.8533 21.2524 23.5369 21.1357C23.2203 21.019 22.874 20.9667 22.525 20.9864C22.4404 20.9911 22.3555 20.9905 22.271 20.9845Z"
        fill="#FFA51E"
      />
    </svg>
  </SVGIcon>
)

export default MoneyBag
