import { SVGIcon } from '../Icons'
import { ISVGProps } from '../types'

const RealEstateExpert = (props: ISVGProps) => (
  <SVGIcon {...props}>
    <svg
      width="45"
      height="44"
      viewBox="0 0 45 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M42.2904 10.5417V26.1667H38.1238V12.625L27.7071 5.33333L17.2904 12.625V15.75H13.1238V10.5417L27.7071 0.125L42.2904 10.5417ZM30.8321 11.5833H28.7488V13.6667H30.8321V11.5833ZM26.6654 11.5833H24.5821V13.6667H26.6654V11.5833ZM30.8321 15.75H28.7488V17.8333H30.8321V15.75ZM26.6654 15.75H24.5821V17.8333H26.6654V15.75ZM38.1238 30.3333H33.9571C33.9571 27.8333 32.3946 25.5833 30.0613 24.7083L17.2279 19.9167H0.623779V42.8333H13.1238V39.8333L27.7071 43.875L44.3738 38.6667V36.5833C44.3738 33.125 41.5821 30.3333 38.1238 30.3333ZM4.79045 38.6667V24.0833H8.95711V38.6667H4.79045ZM27.6446 39.5208L13.1238 35.5V24.0833H16.4779L28.6029 28.6042C29.3113 28.875 29.7904 29.5625 29.7904 30.3333C29.7904 30.3333 25.6446 30.2292 24.9988 30.0208L20.0404 28.375L18.7279 32.3333L23.6863 33.9792C24.7488 34.3333 25.8529 34.5208 26.9779 34.5208H38.1238C38.9363 34.5208 39.6654 35 39.9988 35.6875L27.6446 39.5208Z"
        fill="#FFA51E"
      />
    </svg>
  </SVGIcon>
)

export default RealEstateExpert
