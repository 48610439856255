const PricingPlansData = [
  {
    type: 'Basic',
    price: '$99 Now',
    closingPrice: '$500 at Closing',
    helperText: '',
    details: [
      'Listing on Your Local MLS',
      'Property Will Appear on: Zillow, Trulia, Realtor.com, Redfin, Remax, Homes.com, Etc.*',
      'Access to ShowingTime App & Text for Instant Showing Requests & Feedback*',
      'Access to Virtual Tour App Live Virtual / Video Showings',
      'Traditional Post Yard Sign',
    ],
  },
  {
    type: 'Premium',
    price: '$599 Now',
    closingPrice: '$1,100 at Closing',
    helperText: 'All the Basic Features Plus:',
    details: [
      'Professional Photography',
      'Open House Signs (2)',
      'Seller’s Paperwork',
      'Custom Marketing Flyer',
      'Offer Review & Counteroffer Preparation',
    ],
  },
  {
    type: 'Platinum',
    price: '$699 Now',
    closingPrice: '$2,300 at Closing',
    helperText: 'All the Basic & Premium Features Plus:',
    details: [
      'Offer Negotiation',
      'Professional Photography Including Aerial Photography',
      'Comparative Pricing Analysis',
      'Staging Consultation',
      'Digital Lockbox',
      'Dedicated Real Estate Professional Throughout the Sale',
      'Email Marketing to Local Agents',
    ],
  },
]

export default PricingPlansData
