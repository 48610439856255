import { FunctionComponent, useContext, useState } from 'react'
import styled, { ThemeContext } from 'styled-components'

import SalePriceSlider from 'modules/Sliders/SalePriceSlider'

import StandardButton from 'design-system/Buttons/StandardButton'
import MoneyBag from 'design-system/Iconography/SVG/MoneyBag'
import RealEstateExpert from 'design-system/Iconography/SVG/RealEstateExpert'
import SmileyFace from 'design-system/Iconography/SVG/SmileyFace'
import { Grid, GridItem } from 'design-system/Layouts/Grid'
import Body2 from 'design-system/Typography/Body/Body2'
import CallOut2 from 'design-system/Typography/CallOut/CallOut2'
import Heading2 from 'design-system/Typography/Heading/Heading2'
import Heading4 from 'design-system/Typography/Heading/Heading4'
import Subheading1 from 'design-system/Typography/Subheading/Subheading1'
import Title1 from 'design-system/Typography/Title/Title1'

import Spacer from 'components/Spacer'
import ViewportMarginContainer from 'components/ViewportMarginContainer'

import { addCommas } from 'utils/stringFormatting'

const TOP_COMMISSION = 0.06
const USER_COMMISSION = 0.035
const BASIC_PACKAGE_PRICE = 599

const Background = styled.div`
  background: linear-gradient(90deg, rgba(18, 153, 236, 1) 0%, rgba(7, 191, 241, 1) 100%);
`

const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;
`

const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background-color: ${props => props.theme.colors.white};
  margin: 0 auto;
`

const SliderContainer = styled.div`
  width: 100%;
  padding: 32px 12px;
  background-color: ${props => props.theme.colors.white};
  border-radius: ${props => props.theme.radii[24]}px;

  @media (min-width: ${props => props.theme.metrics.tablet}px) {
    padding: 48px;
  }
  @media (min-width: ${props => props.theme.metrics.desktop}px) {
    padding: 56px;
  }
`

const KeepValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (min-width: ${props => props.theme.metrics.tablet}px) {
    flex-direction: row;
  }
`

const SellSmarter: FunctionComponent = () => {
  const theme = useContext(ThemeContext)

  const [selectedPrice, setSelectedPrice] = useState(320000)

  const brokerSavings = addCommas(Math.floor(selectedPrice * USER_COMMISSION - BASIC_PACKAGE_PRICE))

  const noBrokerSavings = addCommas(
    Math.floor(selectedPrice * TOP_COMMISSION - BASIC_PACKAGE_PRICE),
  )

  return (
    <Background>
      <ViewportMarginContainer center>
        <Spacer
          size={theme.spacings[32]}
          tabletSize={theme.spacings[48]}
          desktopSize={theme.spacings[72]}
        />
        <LayoutContainer>
          <ContentContainer>
            <Heading2
              textAlign="center"
              color="white"
            >
              Sell Smarter
            </Heading2>
            <Spacer size={theme.spacings[16]} />
            <Subheading1
              color="white"
              textAlign="center"
            >
              Save time and money while getting help from real estate experts every step of the way.
            </Subheading1>
            <Spacer
              size={theme.spacings[36]}
              tabletSize={theme.spacings[40]}
              desktopSize={theme.spacings[56]}
            />
            <Grid
              gridColumnsMobile="1fr"
              gridColumns="1fr 1fr 1fr"
              columnGapTablet="40px"
              columnGapDesktop="100px"
              rowGap="36px"
            >
              <GridItem>
                <Circle>
                  <RealEstateExpert
                    width={50}
                    height={50}
                    fill={theme.colors.gold600}
                  />
                </Circle>
                <Spacer
                  size={theme.spacings[16]}
                  tabletSize={theme.spacings[24]}
                  desktopSize={theme.spacings[36]}
                />
                <Title1
                  textAlign="center"
                  color="white"
                >
                  Expert Help
                </Title1>
                <Spacer
                  size={theme.spacings[16]}
                  tabletSize={theme.spacings[24]}
                  desktopSize={theme.spacings[36]}
                />
                <Body2
                  textAlign="center"
                  color="white"
                >
                  Support 7 days a week and a team of real estate professionals to help you through
                  the process. Choose how much help you need.
                </Body2>
              </GridItem>
              <GridItem>
                <Circle>
                  <SmileyFace
                    width={50}
                    height={50}
                    fill={theme.colors.gold600}
                  />
                </Circle>
                <Spacer
                  size={theme.spacings[16]}
                  tabletSize={theme.spacings[24]}
                  desktopSize={theme.spacings[36]}
                />
                <Title1
                  textAlign="center"
                  color="white"
                >
                  Exceptional Technology
                </Title1>
                <Spacer
                  size={theme.spacings[16]}
                  tabletSize={theme.spacings[24]}
                  desktopSize={theme.spacings[36]}
                />
                <Body2
                  textAlign="center"
                  color="white"
                >
                  Our tech platform empowers you to sell smarter, enabling you to keep more of your
                  equity. Manage your listing and sale with our online dashboard and tools.
                </Body2>
              </GridItem>
              <GridItem>
                <Circle>
                  <MoneyBag
                    width={50}
                    height={50}
                    fill={theme.colors.gold600}
                  />
                </Circle>
                <Spacer
                  size={theme.spacings[16]}
                  tabletSize={theme.spacings[24]}
                  desktopSize={theme.spacings[36]}
                />
                <Title1
                  textAlign="center"
                  color="white"
                >
                  Low Flat Fees
                </Title1>
                <Spacer
                  size={theme.spacings[16]}
                  tabletSize={theme.spacings[24]}
                  desktopSize={theme.spacings[36]}
                />
                <Body2
                  textAlign="center"
                  color="white"
                >
                  Flat fees based on the amount of work we do, never a percentage of your home sale,
                  means more of that hard earned equity stays in your pocket.
                </Body2>
              </GridItem>
            </Grid>
            <Spacer
              size={theme.spacings[36]}
              tabletSize={theme.spacings[40]}
              desktopSize={theme.spacings[56]}
            />
            <SliderContainer>
              <Heading4 textAlign="center">
                You worked hard for that equity. We want you to keep it.
              </Heading4>
              <Spacer
                size={10}
                desktopSize={54}
              />
              <SalePriceSlider
                popoverLabel="My Home Value"
                price={selectedPrice}
                onChange={setSelectedPrice}
              />
              <Spacer
                size={24}
                responsiveSize={16}
              />
              <KeepValueContainer>
                <Subheading1>Your Commission Savings:</Subheading1>
                <Spacer size={8} />
                <CallOut2 color="gold600">{` $${brokerSavings} to $${noBrokerSavings}`}</CallOut2>
              </KeepValueContainer>
            </SliderContainer>
            <Spacer
              size={theme.spacings[36]}
              tabletSize={theme.spacings[40]}
              desktopSize={theme.spacings[56]}
            />
            <StandardButton
              label="Sign up now"
              type="secondary"
              size="medium"
              href="/listing/create"
            />
          </ContentContainer>
        </LayoutContainer>
        <Spacer
          size={theme.spacings[32]}
          tabletSize={theme.spacings[48]}
          desktopSize={theme.spacings[72]}
        />
      </ViewportMarginContainer>
    </Background>
  )
}

export default SellSmarter
