import styled from 'styled-components'

interface IImageContainerProps {
  borderRadius?: string
}

const ImageContainer = styled.div<IImageContainerProps>`
  img {
    border-radius: ${props => props.borderRadius || 'inherit'};
  }
`

export default ImageContainer
