import { FunctionComponent, RefObject, useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'

import DownwardCircleButton from 'design-system/Buttons/DownArrowButton'
import AddressSearchInput from 'design-system/Inputs/AddressSearchInput'
import Heading1 from 'design-system/Typography/Heading/Heading1'
import Subheading1 from 'design-system/Typography/Subheading/Subheading1'

import Spacer from 'components/Spacer'
import ViewportMarginContainer from 'components/ViewportMarginContainer'

import { useWindowSize } from 'services/hooks'

interface IImgSrcProps {
  imgSrc: string
  tabletImgSrc?: string
  desktopImgSrc?: string
}

const HeroImageContainer = styled.div<IImgSrcProps>`
  position: relative;
  width: 100%;
  height: 560px;
  background: bottom / cover no-repeat url(${props => props.imgSrc});
  @media (min-width: ${props => props.theme.metrics.tablet}px) {
    ${props => props.tabletImgSrc && `background-image: url(${props.tabletImgSrc});`}
  }
  @media (min-width: ${props => props.theme.metrics.desktop}px) {
    height: 750px;
    ${props => props.desktopImgSrc && `background-image: url(${props.desktopImgSrc});`}
  }
`

const ContentContainer = styled.div`
  & > * {
    z-index: ${props => props.theme.zIndices.level2};
  }

  display: flex;
  flex-direction: column;
  text-align: left;
  @media (min-width: ${props => props.theme.metrics.tablet}px) {
    align-items: flex-start;
  }
`
interface IProps {
  destinationRef?: RefObject<HTMLDivElement> | null
}
const Hero: FunctionComponent<IProps> = ({ destinationRef }) => {
  const theme = useContext(ThemeContext)
  const { width } = useWindowSize()
  const isMobile = width && width < theme.metrics.tablet

  return (
    <HeroImageContainer
      imgSrc={`${process.env.CDN_BASE_URL}/q_auto,w_720,h_0.65,c_crop/amazon/assets/hp-b/suburban-houses-homelister-yard-sign.jpg`}
      tabletImgSrc={`${process.env.CDN_BASE_URL}/q_auto,w_1440,h_0.65,c_crop/amazon/assets/hp-b/suburban-houses-homelister-yard-sign.jpg`}
      desktopImgSrc={`${process.env.CDN_BASE_URL}/q_auto,w_1920,h_0.65,c_crop/amazon/assets/hp-b/suburban-houses-homelister-yard-sign.jpg`}
    >
      <ViewportMarginContainer>
        <Spacer
          size={80}
          desktopSize={140}
        />
        <ContentContainer>
          {isMobile
            ? (
              <>
                <Heading1 color="white">It’s your equity.</Heading1>
                <Heading1 color="white">Keep more of it.</Heading1>
              </>
            )
            : (
              <Heading1 color="white">It’s your equity. Keep more of it.</Heading1>
            )}
          <Spacer
            size={theme.spacings[24]}
            tabletSize={theme.spacings[32]}
            desktopSize={theme.spacings[36]}
          />
          {isMobile
            ? (
              <>
                <Subheading1 color="white">Better experience selling your home.</Subheading1>
                <Subheading1 color="white">Low flat fees. Exceptional technology.</Subheading1>
              </>
            )
            : (
              <Subheading1 color="white">
              Better experience selling your home. Low flat fees. Exceptional technology.
              </Subheading1>
            )}
          <Spacer
            size={theme.spacings[32]}
            desktopSize={theme.spacings[36]}
          />
          <AddressSearchInput type="colorTypeB" />
        </ContentContainer>
      </ViewportMarginContainer>
      <DownwardCircleButton
        fill="gold600"
        destinationRef={destinationRef}
        scrollTopOffset={185}
      />
    </HeroImageContainer>
  )
}

export default Hero
